import React from "react";
import classNames from "classnames";
import { Container, Text } from "@atoms";

const FinancialTable = ({ tables, disclaimer }) => {
  const rowColors = {
    coral: "bg-coral border-coral",
    lightGreen: "bg-light-green border-x-light-green",
  };
  return (
    <Container frame className="mb-14">
      {!!tables?.length && (
        <table className="grid w-full grid-cols-1 gap-8 md:grid-cols-10">
          {tables.map((t, ti) => {
            const showHeading = t.some(c => c.heading);
            const matchHeight =
              !showHeading && tables?.[ti]?.length === tables?.[ti - 1]?.length;
            return (
              <tbody
                className={classNames(
                  "col-span-full w-full border-collapse font-bold",
                  {
                    "md:col-span-6": showHeading,
                    "md:col-span-4": !showHeading,
                    grid: matchHeight,
                  }
                )}
              >
                {t.map((tr, i) => {
                  const Tag = i === 0 ? "th" : "td";
                  return (
                    <tr
                      className={classNames(
                        "h-10 w-full text-left",
                        rowColors[tr.color],
                        {
                          "border-x-2 text-sm": tr.color === "transparent",
                          "border-b-2":
                            (tr?.currentYear || tr?.previousYear) &&
                            tr.color === "transparent" &&
                            !t[i + 1]?.group,
                          "border-x-2 text-black": tr.color !== "transparent",
                          "font-neutral":
                            (showHeading && (i === 0 || !tr.heading)) ||
                            (!showHeading && tr.color !== "transparent"),
                          "grid grid-cols-2": !showHeading,
                          "grid grid-cols-8": showHeading,
                        }
                      )}
                    >
                      {showHeading && (
                        <th
                          scope="row"
                          className={classNames("col-span-4 w-full p-2 py-3", {
                            "text-sm":
                              tr.heading && rowColors[tr.color] && i !== 0,
                          })}
                        >
                          {tr?.heading}
                        </th>
                      )}
                      <Tag
                        scope={i === 0 && "col"}
                        className={classNames("w-full p-2 py-3", {
                          "col-span-2": showHeading,
                          "col-span-1": !showHeading,
                          "font-neutral":
                            tr.heading && tr.color !== "transparent",
                        })}
                      >
                        {tr?.currentYear}
                      </Tag>
                      <Tag
                        scope={i === 0 && "col"}
                        className={classNames("w-full p-2 py-3", {
                          "col-span-2": showHeading,
                          "col-span-1": !showHeading,
                          "font-neutral":
                            tr.heading && tr.color !== "transparent",
                        })}
                      >
                        {tr?.previousYear}
                      </Tag>
                    </tr>
                  );
                })}
              </tbody>
            );
          })}
          {disclaimer && (
            <Text tag="caption" className="col-span-full text-left text-xs">
              {disclaimer}
            </Text>
          )}
        </table>
      )}
    </Container>
  );
};

FinancialTable.defaultProps = {};

export default FinancialTable;
